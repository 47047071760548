export default {
  name: "ListMDeposit",
  data() {
    return {
      property: {
        listElement: {
          deposit: {
            rows: 0,
            perPage: 10,
            currentPage: 1,
            message: "",
            downloading: false,
          },
        },
        filterDto: {
          name: "",
        },
      },
      options: {
        mataUang: [],
      },
      table: {
        data: {
          deposit: [],
        },
      },
    };
  },
  methods: {
    async searchByNameOnProdukDeposito(event) {
      this.table.data.deposit = [];
      this.property.listElement.deposit.downloading = true;
      const timeout = 500;

      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "deposit",
          payload: {
            name: event,
            page: 0,
            size: this.property.listElement.deposit.perPage,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.deposit = resp.data.data.content;
            this.property.listElementdeposit.currentPage =
              resp.data.data.pageable.pageNumber + 1;
            this.property.listElement.deposit.rows =
              resp.data.data.totalElements;
          } else {
            this.failedGetDataDeposit(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleErrorGetDeposit(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.deposit.downloading = false;
        }, timeout + 500);
      }
    },
    async deleteProdukDeposito(props) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.$buefy.dialog.confirm({
          message: "Hapus Data Ini ?",
          type: "is-success",
          onConfirm: async () => {
            try {
              const resp = await this.$store.dispatch({
                type: "DELETE_DATA",
                endPoint: "transaction",
                reqUrl: "deposit/" + props.row.depositId,
              });
              if (resp.data.code === "SUCCESS") {
                this.$buefy.toast.open(this.$NotificationUtils.success);
                setTimeout(() => {
                  this.property.listElement.deposit.currentPage = 1;
                  this.property.filterDto.name = "";
                  this.property.listElement.deposit.rows = 0;
                  this.getDeposit();
                }, 1000);
              } else {
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: resp.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        resp.data.errorFields
                      )
                    : resp.data.message,
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }
            } catch (error) {
              this.$buefy.dialog.alert({
                title: "Gagal !",
                message: error.response
                  ? error.response.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        error.response.data.errorFields
                      )
                    : error.response.statusText
                  : "Terjadi Kesalahan",
                confirmText: "Ok",
                type: "is-danger",
              });
            }
          },
        });
      }
    },
    authorizationDeposit(props) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.$buefy.dialog.confirm({
          message: "Otorisasi Data Ini ?",
          type: "is-success",
          onConfirm: async () => {
            try {
              const resp = await this.$store.dispatch({
                type: "PUT_DATA_AUTHORIZED",
                endPoint: "transaction",
                reqUrl: "deposit/authorized/" + props.row.depositId,
              });
              if (resp.data.code === "SUCCESS") {
                this.$buefy.toast.open(this.$NotificationUtils.success);
                setTimeout(() => {
                  this.property.listElement.deposit.currentPage = 1;
                  this.property.filterDto.name = "";
                  this.property.listElement.deposit.rows = 0;
                  this.getDeposit();
                }, 1000);
              } else if (resp.data.code === "PLEASE_WAIT_IN_THE_PROCESS") {
                this.$buefy.dialog.alert({
                  title: "Harap Tunggu !",
                  message: resp.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        resp.data.errorFields
                      )
                    : resp.data.message,
                  confirmText: "Ok",
                  type: "is-danger",
                });
              } else {
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: resp.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        resp.data.errorFields
                      )
                    : resp.data.message,
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }
            } catch (error) {
              console.log(error.response);
              this.$buefy.dialog.alert({
                title: "Gagal !",
                message: error.response
                  ? error.response.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        error.response.data.errorFields
                      )
                    : error.response.statusText
                  : "Terjadi Kesalahan",
                confirmText: "Ok",
                type: "is-danger",
              });
            }
          },
        });
      }
    },
    routeToPageAddDeposit() {
      this.$router.push("add");
    },
    routeToPageEditDeposit(props) {
      sessionStorage.setItem("DEPOSIT_ID_EDIT", props.row.depositId);
      this.$router.push("edit");
    },
    routeToPageDetailDeposit(props) {
      sessionStorage.setItem("DEPOSIT_ID_DETAIL", props.row.depositId);
      this.$router.push("detail");
    },
    findCurrencyColumnTabelCurrency(id) {
      const currencyFind = this.options.mataUang.find(
        (index) => index.value === id
      );
      return currencyFind ? currencyFind.text : "";
    },
    handleErrorGetDeposit(error) {
      console.log(error.response);
      this.table.data.deposit = [];
      this.property.listElement.deposit.rows = 0;
      this.property.listElement.deposit.currentPage = 1;
      this.property.listElement.deposit.message = error.response
        ? `Gagal Mengunduh Data : ${error.response.statusText}`
        : "Terjadi Kesalahan";
    },
    async changePaginationProdukDeposito(event) {
      this.table.data.deposit = [];
      this.property.listElement.deposit.downloading = true;
      const timeout = 500;

      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "deposit",
          payload: {
            name: this.property.filterDto.name,
            page: event - 1,
            size: this.property.listElement.deposit.perPage,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.deposit = resp.data.data.content;
            this.property.listElement.deposit.rows =
              resp.data.data.totalElements;
          } else {
            this.failedGetDataDeposit(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleErrorGetDeposit(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.deposit.downloading = false;
        }, timeout);
      }
    },
    async getDeposit() {
      this.table.data.deposit = [];
      this.property.listElement.deposit.downloading = true;
      const timeout = 500;

      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "deposit",
          payload: {
            name: "",
            page: 0,
            size: 10,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.deposit = resp.data.data.content;
            this.property.listElement.deposit.rows =
              resp.data.data.totalElements;
          } else {
            this.failedGetDataDeposit(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleErrorGetDeposit(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.deposit.downloading = false;
        }, timeout);
      }
    },
    failedGetDataDeposit(resp) {
      this.table.data.deposit = [];
      this.property.listElement.deposit.rows = 0;
      this.property.listElement.deposit.message = resp.data.message;
    },
    async getReferenceCurrency() {
      try {
        const resp = await this.$store.dispatch(
          "GET_REFERENCE_FROM_MASTER_PARAMETER",
          {
            params: {
              currencyName: "",
              page: 0,
            },
            url: "m-currency",
          }
        );
        if (resp.data.code === "SUCCESS") {
          resp.data.data.content.map((i) => {
            const text = i.currencyName;
            const value = i.currencyId;
            this.options.mataUang.push({ text, value });
          });
        }
      } catch (error) {}
    },
  },
  mounted() {
    this.getDeposit();
    this.getReferenceCurrency();
  },
};
